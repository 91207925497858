import React from "react"
import * as S from "./book-card.styles"
import { graphql, navigate, useStaticQuery } from "gatsby"

const BookCard = ({
  bookCover,
  authorImage,
  authorName,
  role,
  link,
  spacing,
}) => {
  const staticImages = useStaticQuery(
    graphql`
      query {
        authorAvatar: file(relativePath: { eq: "user-placeholder.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2080) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <S.CardContainer onClick={() => navigate(link)} spacing={spacing}>
      {/*<S.CustomBox boxShadow={4}>*/}
      <S.BookCover className="bookCover" img={bookCover} />
      {/*</S.CustomBox>*/}
      {authorName && (
        <S.AuthorContainer>
          {authorImage && (
            <S.AuthorImageContainer>
              <S.AuthorImage img={authorImage} />
            </S.AuthorImageContainer>
          )}

          <S.AuthorName>{authorName}</S.AuthorName>
          <S.Role>{role}</S.Role>
        </S.AuthorContainer>
      )}
    </S.CardContainer>
  )
}

export default BookCard
