import { Container, Grid, useTheme } from "@mui/material"
import React, { useRef, useState } from "react"
import * as S from "./radius-publishes-slider.styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "react-modal-video/css/modal-video.min.css"
import { PlayCircle } from "@mui/icons-material"

const RadiusPublishesSlider = ({ slides }) => {
  const [isOpen, setIsOpen] = useState(false)
  require("html-react-parser")

  const videoRef = useRef(null)

  return (
    <>
      <S.ModalVideo
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="md"
        PaperProps={{ square: true }}
        onRendered={() =>
          videoRef && videoRef.current ? videoRef.current.play() : ""
        }
      >
        <video controls={true} autoPlay={true} ref={videoRef}>
          <source
            src="https://admin.radiusbookgroup.com/wp-content/uploads/2021/02/RADIUS-BOOK-GROUP-2-MINUTE-EDIT-02-08-21-1.mp4"
            type="video/mp4"
          />
        </video>
      </S.ModalVideo>

      <S.Wrapper contained maxWidth="md">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6.5}>
            <S.Item>
              <S.Title fontSize={3} className="title">
                Radius Publishes with Passion and Purpose.
              </S.Title>
              <S.Description fontSize={1}>
                Learn about the Radius Experience
              </S.Description>
            </S.Item>
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <S.ContentWrapper className="video">
              <S.InnerWrapper>
                <S.Circle onClick={() => setIsOpen(true)}>
                  <PlayCircle />
                </S.Circle>
              </S.InnerWrapper>
              <S.VideoBg
                autoPlay
                muted
                loop
                preload="metadata"
                playsInline
                src="radius-short-authorstills.mp4#t=0.2"
              />
            </S.ContentWrapper>
          </Grid>
        </Grid>
      </S.Wrapper>
    </>
  )
}

export default RadiusPublishesSlider
