import styled from "styled-components"
import Container from "@mui/material/Container"
import { Typography } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.palette.primary.light};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(60)};
  font-weight: bold;
  letter-spacing: 3.75px;
  line-height: 69px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    letter-spacing: 3.19px;
    line-height: 57px;
    font-size: ${({ theme }) => theme.typography.pxToRem(51)};
  }
`
export const TitleContainer = styled.div`
  margin-bottom: 2em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 240px;
    margin-bottom: 0;
  }
`
export const CustomContainer = styled(Container)`
  margin-top: 6em;
  border-bottom: rgb(216, 216, 216, 0.2) 1px solid;
`
export const Description = styled(Typography)`
  margin-top: 0.5em;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: 29px;
`
export const CardTitle = styled(CustomLink)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: 29px;
  font-weight: normal;
`
