import * as S from "../AboutWhy/about-why.styles"
import React from "react"
import Grid from "@mui/material/Grid"

const AboutWhy = ({ leftTitle, columns }) => {
  // const cards = [
  //   {
  //     title: "Retain control",
  //     description:
  //       "Traditional publishing requires giving up control over your release strategy, your image, and even your voice. We think authors deserve better. If it’s not your voice, whose book is it, anyway?",
  //   },
  //   {
  //     title: "Publish a better book",
  //     description:
  //       "The big 5 publishers need to release thousands of books each year, and many arrive riddled with errors due to rushed editing. Our focused approach yields error-free books, every time.",
  //   },
  //   {
  //     title: "Enjoy flexibility",
  //     description:
  //       "Our unique model enables incomparable flexibility when it comes to when and how you publish your book. We’ll help you develop the perfect distribution strategy for achieving your publishing and business goals.",
  //   },
  //   {
  //     title: "Think 360°",
  //     description:
  //       "To us, bookselling is just the beginning. We constantly look for derivative opportunities to turn your story into an audiobook, a film, or a podcast.",
  //   },
  // ]
  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth="md">
        <Grid container>
          <Grid item xs={12} md={4}>
            <S.TitleContainer>
              <S.Title>{leftTitle}</S.Title>
            </S.TitleContainer>
          </Grid>
          {columns && columns.length > 0 && (
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                {columns.map(card => (
                  <Grid item md={6} style={{ marginBottom: "2em" }}>
                    <S.CardTitle url={card.url ? card.url : null}>
                      {card.title}
                    </S.CardTitle>
                    <S.Description>{card.content}</S.Description>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}
export default AboutWhy
